import { useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getPublicAppSettings } from 'redux/features/app/appSlice'
import { isFailed, isSuccess } from 'redux/toolkit/api'

export enum UiState {
  INITIALIZING,
  READY
}

export const useAppLogic = () => {
  const dispatch = useAppDispatch()
  const [uiState, setUiState] = useState(UiState.INITIALIZING)
  const { isGetPublicSettingsSuccess, isGetPublicSettingsFailed } = useAppSelector(_store => ({
    isGetPublicSettingsSuccess: isSuccess(_store.app.api.getPublicAppSettingsApiStatus),
    isGetPublicSettingsFailed: isFailed(_store.app.api.getPublicAppSettingsApiStatus)
  }))

  useEffect(() => {
    dispatch(getPublicAppSettings())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isGetPublicSettingsSuccess || isGetPublicSettingsFailed) {
      setUiState(UiState.READY)
    }
  }, [isGetPublicSettingsFailed, isGetPublicSettingsSuccess])

  return useMemo(() => ({ uiState }), [uiState])
}
