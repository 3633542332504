import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'redux/toolkit/api'
import { LocalStorageKeys } from 'lib/types/localStorageTypes'
import {
  ActionStatus,
  ActionTaken,
  AttachmentDetail,
  Message,
  MessageDirection,
  MessageReport,
  MessageSource,
  MessageUpdateReport,
  ModifiedReportMessage,
  Reason,
  RedeliverMessage,
  ReportMessage,
  SavedSearch,
  Search,
  SearchFilter
} from 'types/Messages'
import { getEndDate, getStartDate } from 'lib/mstore'
import {
  MlogViewType,
  removeListItems,
  resetSelectedMessage,
  setDownloadAttachmentProgress,
  setDownloadMessageProgress
} from 'redux/features/mstore/mstoreActions'
import {
  deleteMessage,
  downloadAttachment,
  DownloadAttachmentResponse,
  downloadMessage,
  DownloadMessageResponse,
  getAttachment,
  getMessage,
  getMessageSource,
  getRedeliveryQueueMessages,
  GetRedeliveryQueueMessagesResponse,
  getSearch,
  postAllowSender,
  postBlockSender,
  postNewEmail,
  postRecategorize,
  postRedeliverMessage,
  PostRedeliverMessageResponse,
  postRejectMessages
} from 'redux/features/mstore/mstoreApiThunks'
import { HardResetReport, resetReport } from 'redux/features/dataTables/reduceHandlers'
import { ITEMS_PER_PAGE } from 'redux/features/dataTables/messages/messagesSlice'
import { convertQNDigestTDFLinksToReact } from 'lib/routesConfig'

export interface MlogViewConfig {
  type: MlogViewType
  title: string
  table: {
    showDeliveryColumn: boolean
    showEncryptionColumn: boolean
    showAttachmentColumn: boolean
    showReasonColumn: boolean
    showScoreColumn: boolean
  }
  filters: {
    showMessageDirection: boolean
    showAction: boolean
    showStatus: boolean
    showReason: boolean
    showDatePresets: boolean
  }
  advancedSearch: {
    showAttachmentName: boolean
  }
  listToolbar: {
    showRecategorize: boolean
    showDirectDeliver: boolean
    showOpenRedeliverDialog: boolean
    showReject: boolean
  }
  detailsToolbar: {
    showHistoryToggle: boolean
    showBlockEmail: boolean
    showBlockDomain: boolean
    showReject: boolean
    showReport: boolean
  }
}

export const MESSAGE_LOG_VIEW_CONFIG: MlogViewConfig = {
  type: MlogViewType.MESSAGE_LOG,
  title: 'title',
  table: {
    showDeliveryColumn: true,
    showEncryptionColumn: true,
    showAttachmentColumn: true,
    showReasonColumn: true,
    showScoreColumn: true
  },
  filters: {
    showMessageDirection: true,
    showAction: true,
    showStatus: true,
    showReason: true,
    showDatePresets: true
  },
  advancedSearch: {
    showAttachmentName: true
  },
  listToolbar: {
    showRecategorize: true,
    showDirectDeliver: false,
    showOpenRedeliverDialog: true,
    showReject: false
  },
  detailsToolbar: {
    showHistoryToggle: true,
    showBlockEmail: true,
    showBlockDomain: true,
    showReject: false,
    showReport: true
  }
}

export const OUTBOUND_QUARANTINE_VIEW_CONFIG: MlogViewConfig = {
  type: MlogViewType.OUTBOUND_QUARANTINE,
  title: 'outbound_quarantine_title',
  table: {
    showDeliveryColumn: false,
    showEncryptionColumn: false,
    showAttachmentColumn: false,
    showReasonColumn: false,
    showScoreColumn: false
  },
  filters: {
    showMessageDirection: false,
    showAction: false,
    showStatus: false,
    showReason: false,
    showDatePresets: false
  },
  advancedSearch: {
    showAttachmentName: false
  },
  listToolbar: {
    showRecategorize: false,
    showDirectDeliver: true,
    showOpenRedeliverDialog: false,
    showReject: true
  },
  detailsToolbar: {
    showHistoryToggle: false,
    showBlockEmail: false,
    showBlockDomain: false,
    showReject: true,
    showReport: false
  }
}

export interface MstoreState {
  getMessageApiStatus: ApiStatus
  getMessageSourceApiStatus: ApiStatus
  getSearchApiStatus: ApiStatus
  postBlockSenderApiStatus: ApiStatus
  postAllowedSenderApiStatus: ApiStatus
  postNewEmailApiStatus: ApiStatus
  getAttachmentApiStatus: ApiStatus
  getRedeliveryQueueApiStatus: ApiStatus
  postRedeliveryApiStatus: ApiStatus
  postRecategorizeApiStatus: ApiStatus
  deleteMessageApiStatus: ApiStatus
  downloadMessageApiStatus: ApiStatus
  downloadAttachmentApiStatus: ApiStatus
  rejectMessagesApiStatus: ApiStatus

  message: Message | undefined
  messageSource: MessageSource | undefined
  messagesHash: { [key: Message['mid']]: Message } | Record<string, never>
  attachmentDetail: AttachmentDetail | undefined

  // Message search properties
  isMessageHistoryOpen: boolean
  search: MessageReport | Record<string, never>
  selectedMessageMid: string | undefined
  checkedMessages: ReportMessage[]
  redeliveryQueueMessages: RedeliverMessage[]
  searchTerm: Search
  searchFilter: SearchFilter
  savedSearchForms: SavedSearch[]

  blockResult: MessageUpdateReport | undefined
  allowResult: MessageUpdateReport | undefined
  redeliveryResult: PostRedeliverMessageResponse | undefined
  redeliveryQueue: GetRedeliveryQueueMessagesResponse | undefined
  recategorizeResult: MessageUpdateReport | undefined

  downloadedMessage: DownloadMessageResponse | undefined
  downloadMessageProgressTotal: number
  downloadMessageProgressLoaded: number
  downloadMessageProgressPercent: number

  downloadedAttachment: DownloadAttachmentResponse | undefined
  downloadAttachmentProgressTotal: number
  downloadAttachmentProgressLoaded: number
  downloadAttachmentProgressPercent: number

  showImages: boolean

  viewConfig: MlogViewConfig

  deletedMessageIds: string[] | undefined

  rejectMessagesResponse: any
}

const saveSearchFormsInLocalStorage = (viewType: MlogViewType) => {
  const collectionName =
    viewType === MlogViewType.MESSAGE_LOG ? LocalStorageKeys.searchForm : LocalStorageKeys.outboundQuarantineSearchForm
  return (searchForms: SavedSearch[]) => {
    localStorage.setItem(collectionName, JSON.stringify(searchForms))
  }
}

const loadSearchFormsFromLocalStorage = (viewType: MlogViewType) => {
  const collectionName =
    viewType === MlogViewType.MESSAGE_LOG ? LocalStorageKeys.searchForm : LocalStorageKeys.outboundQuarantineSearchForm
  return () => {
    const localSearchForms = localStorage.getItem(collectionName)
    return localSearchForms ? JSON.parse(localSearchForms) : []
  }
}

// initialState
export const INITIAL_STATE: MstoreState = {
  getMessageApiStatus: inIdle,
  getMessageSourceApiStatus: inIdle,
  getSearchApiStatus: inIdle,
  postBlockSenderApiStatus: inIdle,
  postAllowedSenderApiStatus: inIdle,
  postNewEmailApiStatus: inIdle,
  getAttachmentApiStatus: inIdle,
  getRedeliveryQueueApiStatus: inIdle,
  postRedeliveryApiStatus: inIdle,
  postRecategorizeApiStatus: inIdle,
  deleteMessageApiStatus: inIdle,
  downloadMessageApiStatus: inIdle,
  downloadAttachmentApiStatus: inIdle,
  rejectMessagesApiStatus: inIdle,

  message: undefined,
  messageSource: undefined,
  messagesHash: {},
  attachmentDetail: undefined,

  // Message search properties
  isMessageHistoryOpen: false,
  search: {},
  selectedMessageMid: undefined,
  checkedMessages: [],
  redeliveryQueueMessages: [],
  searchFilter: {
    delivery_status: ActionStatus.any,
    reason: Reason.any,
    action: ActionTaken.quarantined
  },
  searchTerm: {
    start: getStartDate(2),
    end: getEndDate(),
    offset: 0,
    limit: ITEMS_PER_PAGE,
    search_str: '',
    user_id: undefined,
    domain_id: undefined,
    outbound: MessageDirection.INBOUND,
    dateRange: 2
  },
  savedSearchForms: [],

  blockResult: undefined,
  allowResult: undefined,
  redeliveryResult: undefined,
  redeliveryQueue: undefined,
  recategorizeResult: undefined,

  downloadedMessage: undefined,
  downloadMessageProgressTotal: 0,
  downloadMessageProgressLoaded: 0,
  downloadMessageProgressPercent: 0,

  downloadedAttachment: undefined,
  downloadAttachmentProgressTotal: 0,
  downloadAttachmentProgressLoaded: 0,
  downloadAttachmentProgressPercent: 0,

  showImages: false,

  viewConfig: MESSAGE_LOG_VIEW_CONFIG,

  deletedMessageIds: undefined,

  rejectMessagesResponse: undefined
}

interface ResetSearch {
  hardReset?: HardResetReport
  dateReset?: boolean
}

/* eslint-disable no-param-reassign */
export const mstoreSlice = createSlice({
  name: 'MSTORE',
  initialState: INITIAL_STATE,
  reducers: {
    setIsMessageHistoryOpen: (state, action: PayloadAction<boolean>) => {
      state.isMessageHistoryOpen = action.payload
    },
    selectSearchMessage: (state, action: PayloadAction<string>) => {
      state.selectedMessageMid = action.payload
    },
    checkAllSearchMessages: (state, action: PayloadAction<{ from: number; to: number; isChecked: boolean }>) => {
      for (let index = action.payload.from, length = action.payload.to; index < length; ++index) {
        state.search.messages[index].isChecked = action.payload.isChecked
      }
      state.checkedMessages = action.payload.isChecked
        ? state.search.messages.slice(action.payload.from, action.payload.to)
        : []
    },
    checkSearchMessage: (state, action: PayloadAction<ModifiedReportMessage>) => {
      if (action.payload.isChecked) {
        state.checkedMessages.push(action.payload)
      } else {
        state.checkedMessages = state.checkedMessages.filter(message => message.mid !== action.payload.mid)
      }
      state.search.messages = state.search.messages.map(message =>
        message.mid === action.payload.mid ? action.payload : message
      )
    },
    setRedeliveryQueueMessages: (state, action: PayloadAction<RedeliverMessage[]>) => {
      state.redeliveryQueueMessages = action.payload
    },
    openSearchForm: (state, action: PayloadAction<string>) => {
      const searchForm = state.savedSearchForms.find(form => form.name === action.payload)
      if (searchForm) {
        state.searchTerm = searchForm.searchTerm
        state.searchFilter = searchForm.searchFilter
      }
    },
    saveSearchForm: (state, action: PayloadAction<string>) => {
      state.savedSearchForms.push({
        name: action.payload,
        searchTerm: state.searchTerm,
        searchFilter: state.searchFilter
      })
      saveSearchFormsInLocalStorage(state.viewConfig.type)(state.savedSearchForms)
    },
    removeSavedSearchForm: (state, action: PayloadAction<string>) => {
      state.savedSearchForms = state.savedSearchForms.filter(searchForm => searchForm.name !== action.payload)
      saveSearchFormsInLocalStorage(state.viewConfig.type)(state.savedSearchForms)
    },
    loadSavedSearchForms: state => {
      state.savedSearchForms = loadSearchFormsFromLocalStorage(state.viewConfig.type)()
    },
    setSearchString: (state, action: PayloadAction<string>) => {
      state.searchTerm.search_str = action.payload
    },
    setDateRange: (state, action: PayloadAction<{ days: number | undefined }>) => {
      state.searchTerm.dateRange = action.payload.days
    },
    setSearchDate: (state, action: PayloadAction<{ start: number; end: number }>) => {
      state.searchTerm.start = action.payload.start
      state.searchTerm.end = action.payload.end
    },
    setSearchStartDate: (state, action: PayloadAction<number>) => {
      state.searchTerm.start = action.payload
    },
    setSearchEndDate: (state, action: PayloadAction<number>) => {
      state.searchTerm.end = action.payload
    },
    setSearchUserId: (state, action: PayloadAction<string | undefined>) => {
      state.searchTerm.user_id = action.payload
    },
    resetSearchUserId: state => {
      state.searchTerm.user_id = INITIAL_STATE.searchTerm.user_id
    },
    setSearchDomainId: (state, action: PayloadAction<string | undefined>) => {
      state.searchTerm.domain_id = action.payload
    },
    resetSearchDomainId: state => {
      state.searchTerm.domain_id = INITIAL_STATE.searchTerm.domain_id
    },
    setSearchMessageDirection: (state, action: PayloadAction<MessageDirection>) => {
      state.searchTerm.outbound = action.payload
    },
    resetSearchMessageDirection: state => {
      state.searchTerm.outbound = INITIAL_STATE.searchTerm.outbound
    },
    setSearchFilter: (state, action: PayloadAction<Partial<SearchFilter>>) => {
      state.searchFilter = { ...state.searchFilter, ...action.payload }
    },
    setSearchTerm: (state, action: PayloadAction<Search>) => {
      state.searchTerm = action.payload
    },
    resetSearchParams: state => {
      state.searchTerm = {
        ...INITIAL_STATE.searchTerm,
        start: getStartDate(2),
        end: getEndDate()
      }
      state.searchFilter = INITIAL_STATE.searchFilter
    },
    resetSearch: (state, action: PayloadAction<ResetSearch>) => {
      if (action.payload.dateReset && state.searchTerm.dateRange) {
        const dateDiff = state.searchTerm.end - state.searchTerm.start
        const end = getEndDate()
        const start = end - dateDiff
        state.searchTerm = {
          ...state.searchTerm,
          start,
          end
        }
      }
      state.search = resetReport(state.search, action.payload.hardReset) || INITIAL_STATE.search
      state.getSearchApiStatus = inIdle
    },
    reset: () => ({
      ...INITIAL_STATE
    }),
    resetDownloadMessage: state => {
      state.downloadMessageProgressTotal = INITIAL_STATE.downloadMessageProgressTotal
      state.downloadMessageProgressLoaded = INITIAL_STATE.downloadMessageProgressLoaded
      state.downloadMessageProgressPercent = INITIAL_STATE.downloadMessageProgressPercent
      state.downloadMessageApiStatus = INITIAL_STATE.downloadMessageApiStatus
    },
    setDownloadAttachmentProgress: (state: MstoreState, action: PayloadAction<{ total: number; loaded: number }>) => {
      const { total, loaded } = action.payload
      state.downloadAttachmentProgressTotal = total
      state.downloadAttachmentProgressLoaded = loaded
      state.downloadAttachmentProgressPercent = total === 0 ? 0 : Math.round(loaded / total)
    },
    resetDownloadAttachment: state => {
      state.downloadAttachmentProgressTotal = INITIAL_STATE.downloadAttachmentProgressTotal
      state.downloadAttachmentProgressLoaded = INITIAL_STATE.downloadAttachmentProgressLoaded
      state.downloadAttachmentProgressPercent = INITIAL_STATE.downloadAttachmentProgressPercent
      state.downloadAttachmentApiStatus = INITIAL_STATE.downloadAttachmentApiStatus
    },
    setShowImages: (state, action: PayloadAction<boolean>) => {
      state.showImages = action.payload
    },
    resetPostNewEmail: state => {
      state.postNewEmailApiStatus = inIdle
    },
    setViewConfig: (state, action: PayloadAction<MlogViewConfig>) => {
      state.viewConfig = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(
        setDownloadAttachmentProgress,
        (state: MstoreState, action: PayloadAction<{ total: number; loaded: number }>) => {
          const { total, loaded } = action.payload
          state.downloadAttachmentProgressTotal = total
          state.downloadAttachmentProgressLoaded = loaded
          state.downloadAttachmentProgressPercent = total === 0 ? 0 : Math.round(loaded / total)
        }
      )
      .addCase(
        setDownloadMessageProgress,
        (state: MstoreState, action: PayloadAction<{ total: number; loaded: number }>) => {
          const { total, loaded } = action.payload
          state.downloadMessageProgressTotal = total
          state.downloadMessageProgressLoaded = loaded
          state.downloadMessageProgressPercent = total === 0 ? 0 : Math.round(loaded / total)
        }
      )
      .addCase(removeListItems, (state, action: PayloadAction<string[]>) => {
        state.search.messages = (state.search.messages || []).filter(item => !action.payload.includes(item.mid))
        state.checkedMessages = state.checkedMessages.filter(item => !action.payload.includes(item.mid))
      })
      .addCase(resetSelectedMessage, state => {
        state.selectedMessageMid = INITIAL_STATE.selectedMessageMid
      })

      // getMessage
      .addCase(getMessage.pending, state => {
        state.message = INITIAL_STATE.message
        state.getMessageApiStatus = inProgress
      })
      .addCase(getMessage.fulfilled, (state, action) => {
        state.message = convertQNDigestTDFLinksToReact(action.payload.message)
        state.messagesHash = {
          ...state.messagesHash,
          [action.payload.message.mid]: action.payload.message
        }
        state.getMessageApiStatus = successResponse
      })
      .addCase(getMessage.rejected, (state, action) => {
        state.getMessageApiStatus = failedResponse(action.payload)
      })

      // getMessageSource
      .addCase(getMessageSource.pending, state => {
        state.getMessageSourceApiStatus = inProgress
      })
      .addCase(getMessageSource.fulfilled, (state, action) => {
        state.messageSource = action.payload
        state.getMessageSourceApiStatus = successResponse
      })
      .addCase(getMessageSource.rejected, (state, action) => {
        state.getMessageSourceApiStatus = failedResponse(action.payload)
      })

      // getAttachment
      .addCase(getAttachment.pending, state => {
        state.attachmentDetail = INITIAL_STATE.attachmentDetail
        state.getAttachmentApiStatus = inProgress
      })
      .addCase(getAttachment.fulfilled, (state, action) => {
        state.attachmentDetail = action.payload
        state.getAttachmentApiStatus = successResponse
      })
      .addCase(getAttachment.rejected, (state, action) => {
        state.getAttachmentApiStatus = failedResponse(action.payload)
      })

      // getSearch
      .addCase(getSearch.pending, state => {
        state.getSearchApiStatus = inProgress
      })
      .addCase(getSearch.fulfilled, (state, action) => {
        state.search = {
          messageCount: action.payload.messageCount,
          messages: action.payload.messages
        }
        state.checkedMessages = INITIAL_STATE.checkedMessages
        state.getSearchApiStatus = successResponse
      })
      .addCase(getSearch.rejected, (state, action) => {
        state.getSearchApiStatus = failedResponse(action.payload)
      })

      // postBlockSender
      .addCase(postBlockSender.pending, state => {
        state.blockResult = INITIAL_STATE.blockResult
        state.postBlockSenderApiStatus = inProgress
      })
      .addCase(postBlockSender.fulfilled, (state, action) => {
        state.blockResult = action.payload
        state.postBlockSenderApiStatus = successResponse
      })
      .addCase(postBlockSender.rejected, (state, action) => {
        state.postBlockSenderApiStatus = failedResponse(action.payload)
      })

      // postAllowSender
      .addCase(postAllowSender.pending, state => {
        state.allowResult = INITIAL_STATE.allowResult
        state.postAllowedSenderApiStatus = inProgress
      })
      .addCase(postAllowSender.fulfilled, (state, action) => {
        state.allowResult = action.payload
        state.postAllowedSenderApiStatus = successResponse
      })
      .addCase(postAllowSender.rejected, (state, action) => {
        state.postAllowedSenderApiStatus = failedResponse(action.payload)
      })

      // postRedeliverMessage
      .addCase(postRedeliverMessage.pending, state => {
        state.redeliveryResult = INITIAL_STATE.redeliveryResult
        state.postRedeliveryApiStatus = inProgress
      })
      .addCase(postRedeliverMessage.fulfilled, (state, action) => {
        state.redeliveryResult = action.payload
        state.postRedeliveryApiStatus = successResponse
      })
      .addCase(postRedeliverMessage.rejected, (state, action) => {
        state.postRedeliveryApiStatus = failedResponse(action.payload)
      })

      // getRedeliveryQueueMessages
      .addCase(getRedeliveryQueueMessages.pending, state => {
        state.getRedeliveryQueueApiStatus = inProgress
      })
      .addCase(getRedeliveryQueueMessages.fulfilled, (state, action) => {
        state.redeliveryQueue = action.payload
        state.getRedeliveryQueueApiStatus = successResponse
      })
      .addCase(getRedeliveryQueueMessages.rejected, (state, action) => {
        state.getRedeliveryQueueApiStatus = failedResponse(action.payload)
      })

      // postRecategorize
      .addCase(postRecategorize.pending, state => {
        state.recategorizeResult = INITIAL_STATE.recategorizeResult
        state.postRecategorizeApiStatus = inProgress
      })
      .addCase(postRecategorize.fulfilled, (state, action) => {
        state.recategorizeResult = action.payload
        state.postRecategorizeApiStatus = successResponse
      })
      .addCase(postRecategorize.rejected, (state, action) => {
        state.postRecategorizeApiStatus = failedResponse(action.payload)
      })

      // deleteMessage
      .addCase(deleteMessage.pending, state => {
        state.deleteMessageApiStatus = inProgress
        state.deletedMessageIds = undefined
      })
      .addCase(deleteMessage.fulfilled, (state, action) => {
        state.deleteMessageApiStatus = successResponse
        state.deletedMessageIds = action.payload
      })
      .addCase(deleteMessage.rejected, (state, action) => {
        state.deleteMessageApiStatus = failedResponse(action.payload)
      })

      // downloadMessage
      .addCase(downloadMessage.pending, state => {
        state.downloadMessageApiStatus = inProgress
        state.downloadedMessage = INITIAL_STATE.downloadedMessage
        state.downloadMessageProgressTotal = INITIAL_STATE.downloadMessageProgressTotal
        state.downloadMessageProgressLoaded = INITIAL_STATE.downloadMessageProgressLoaded
      })
      .addCase(downloadMessage.fulfilled, (state, action) => {
        state.downloadedMessage = action.payload
        state.downloadMessageApiStatus = successResponse
      })
      .addCase(downloadMessage.rejected, (state, action) => {
        state.downloadMessageApiStatus = failedResponse(action.payload)
      })

      // postNewEmail
      .addCase(postNewEmail.pending, state => {
        state.postNewEmailApiStatus = inProgress
      })
      .addCase(postNewEmail.fulfilled, state => {
        state.postNewEmailApiStatus = successResponse
      })
      .addCase(postNewEmail.rejected, (state, action) => {
        state.postNewEmailApiStatus = failedResponse(action.payload)
      })

      // downloadAttachment
      .addCase(downloadAttachment.pending, state => {
        state.downloadAttachmentApiStatus = inProgress
        state.downloadedAttachment = INITIAL_STATE.downloadedAttachment
        state.downloadAttachmentProgressTotal = INITIAL_STATE.downloadAttachmentProgressTotal
        state.downloadAttachmentProgressLoaded = INITIAL_STATE.downloadAttachmentProgressLoaded
      })
      .addCase(downloadAttachment.fulfilled, (state, action) => {
        state.downloadedAttachment = action.payload
        state.downloadAttachmentApiStatus = successResponse
      })
      .addCase(downloadAttachment.rejected, (state, action) => {
        state.downloadAttachmentApiStatus = failedResponse(action.payload)
      })

      // postRejectMessages
      .addCase(postRejectMessages.pending, state => {
        state.rejectMessagesResponse = INITIAL_STATE.rejectMessagesResponse
        state.rejectMessagesApiStatus = inProgress
      })
      .addCase(postRejectMessages.fulfilled, (state, action) => {
        state.rejectMessagesResponse = action.payload
        state.rejectMessagesApiStatus = successResponse
      })
      .addCase(postRejectMessages.rejected, (state, action) => {
        state.rejectMessagesApiStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  setIsMessageHistoryOpen,
  resetDownloadMessage,
  resetDownloadAttachment,
  selectSearchMessage,
  checkAllSearchMessages,
  checkSearchMessage,
  setRedeliveryQueueMessages,
  openSearchForm,
  saveSearchForm,
  removeSavedSearchForm,
  setSearchString,
  setSearchDate,
  setDateRange,
  setSearchStartDate,
  setSearchEndDate,
  setSearchFilter,
  setSearchUserId,
  resetSearchUserId,
  setSearchDomainId,
  resetSearchDomainId,
  setSearchMessageDirection,
  resetSearchMessageDirection,
  setSearchTerm,
  resetSearchParams,
  resetSearch,
  reset,
  setShowImages,
  resetPostNewEmail,
  setViewConfig,
  loadSavedSearchForms
} = mstoreSlice.actions

export {
  MlogViewType,
  setDownloadAttachmentProgress,
  setDownloadMessageProgress,
  removeListItems,
  resetSelectedMessage,
  getMessage,
  getMessageSource,
  getAttachment,
  getSearch,
  postBlockSender,
  postAllowSender,
  getRedeliveryQueueMessages,
  postRedeliverMessage,
  postNewEmail,
  postRecategorize,
  deleteMessage,
  downloadMessage,
  downloadAttachment,
  postRejectMessages
}

export default mstoreSlice.reducer
