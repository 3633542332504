import React, { useMemo } from 'react'
import { Box, Grid, Link, Typography } from '@barracuda-internal/bds-core'
import styles from 'components/libs/layout/footer/loginFooter/loginFooterStyles'
import config from 'config/appConfig'

const LoginFooter = () => {
  const classes = styles()
  const copyrightYears = new Date().getFullYear()

  return useMemo(
    () => (
      <Grid container className={classes.container}>
        <Box>
          <Typography variant="body2" className={classes.footerText}>
            © {copyrightYears} Barracuda Networks, Inc.
          </Typography>
        </Box>
        <Box>
          <Link
            href="https://www.barracuda.com/company/legal/privacy"
            className={classes.footerLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
          <Link
            href="https://www.barracuda.com/legal/terms"
            className={`${classes.footerLink} ${config.ONETRUST_ID ? '' : 'nomargin'}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </Link>
          {config.ONETRUST_ID && (
            <button id="ot-sdk-btn" className="ot-sdk-show-settings">
              Cookie Preferences
            </button>
          )}
        </Box>
      </Grid>
    ),
    [classes.container, classes.footerLink, classes.footerText, copyrightYears]
  )
}

export default LoginFooter
