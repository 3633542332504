import { ChangeEvent, MouseEvent, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { process } from '@progress/kendo-data-query'

import { GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { DataTableState } from 'types/redux/dataTables/dataTables'
import {
  getDomainList,
  manageDomain,
  resetDomainSettings,
  resetEditDomain,
  setDomainNameFilter,
  setDomainTransferComplete
} from 'redux/features/domains/domainsSlice'
import { getErrorMessage, isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { Domain, DomainMoveState, MxStatus } from 'types/domains'
import { SettingValue } from 'types/Settings'
import { trackEventInAllServices, TRACKING_EVENTS } from 'lib/monitoring/monitoringService'
import routesConfig from 'lib/routesConfig'
import { update as updateDomainsTable } from 'redux/features/dataTables/domains/domainsSlice'
import useAppTypeEntryPaths from 'components/libs/routes/useAppTypeEntryPaths'
import { DomainsTabRights, useDomainsTabRights } from 'components/pages/domains/useDomainsTabRights'
import { AppTypes } from 'types/AppTypes'
import { usePollDomainTransferState } from 'components/pages/domains/verifyDomainTransfer/usePollDomainTransferState'

export interface ModifiedDomainManagerItem extends Domain {
  isExpanded: boolean
  hasDomainSpecificSettings: boolean
  emergencyInboxEnabledTextId: string
  isDomainMove: boolean
}

export interface RemoveDomainData {
  domainId: string
  isDomainMove: boolean
}

export interface State {
  page: {
    isPending: boolean
  }
  table: {
    isLoading: boolean
    isSuccess: boolean
    isFailed: boolean
    config: DataTableState
    data: Domain[]
    total: number
    loadedAndNoRecord: boolean
    error: string
  }
  mxBanner: {
    isVisible: boolean
    ref: MutableRefObject<HTMLDivElement | null>
    isPopoverOpen: boolean
  }
  help: {
    isHelpOpen: boolean
  }
  addDomain: {
    isAddDomainOpen: boolean
  }
  removeDomain: {
    isRemoveDomainOpen: boolean
    removeDomainData: RemoveDomainData | undefined
    isRemoveDomainPending: boolean
    isRemoveDomainMovePending: boolean
  }
  manageDomain: {
    buttonLabel: string
    domainId: string | undefined
  }
  searchDomain: {
    domainNameFilter: string
  }
  globalReset: {
    isOpen: boolean
    isPending: boolean
  }
  permissions: DomainsTabRights
}

export interface EventHandlers {
  onRowClick: (event: MouseEvent, message: ModifiedDomainManagerItem) => void
  mxBanner: {
    onClickLearnMore: () => void
    onClosePopover: () => void
  }
  onEditDomain: (e: MouseEvent, domainId: string) => void
  onVerifyDomainOwnership: (e: MouseEvent, dataItem: ModifiedDomainManagerItem) => void
  onVerifyDomainOwnershipAndTransfer: (e: MouseEvent, dataItem: ModifiedDomainManagerItem) => void
  onViewDomainRecipients: (e: MouseEvent, dataItem: ModifiedDomainManagerItem) => void
  onManageDomain: (e: MouseEvent, dataItem: ModifiedDomainManagerItem) => void
  onRemoveDomain: (e: MouseEvent, dataItem: ModifiedDomainManagerItem) => void
  onPageChange: (e: GridPageChangeEvent) => void
  onSortChange: (e: GridSortChangeEvent) => void
  addDomain: {
    onOpenAddDomain: () => void
    onCloseAddDomain: () => void
  }
  help: {
    onHelpOpen: () => void
    onHelpClose: () => void
  }
  removeDomain: {
    onCloseRemoveDomain: () => void
  }
  searchDomain: {
    onChangeDomainNameFilter: (e: ChangeEvent<HTMLInputElement>) => void
    onSearchDomain: () => void
  }
  globalReset: {
    onOpenConfirmDialog: (e: MouseEvent, dataItem: ModifiedDomainManagerItem) => void
    onCloseConfirmDialog: () => void
    onConfirmReset: () => void
  }
}

export type DomainsLogic = [State, EventHandlers]

export const useDomainsLogic = (): DomainsLogic => {
  const [{ domainTransferStates }, poll] = usePollDomainTransferState()
  const polledDomainTransfers = useRef<string[]>([])
  const permissions = useDomainsTabRights()
  const dispatch = useAppDispatch()
  const {
    tableConfig,
    domains,
    totalDomainCount,
    isGetDomainListPending,
    isGetDomainListFailed,
    isGetDomainListSuccess,
    getDomainListError,
    isManageDomainSuccess,
    isManageDomainFailed,
    isRemoveDomainPending,
    domainNameFilter,
    isResetDomainPending,
    isResetDomainSuccess,
    appType,
    isRemoveDomainMovePending
  } = useAppSelector(_store => ({
    tableConfig: _store.dataTables.domains,
    domains: _store.domains.domainList,
    totalDomainCount: _store.domains.totalDomainCount,
    isGetDomainListPending: isPending(_store.domains.api.getDomainListApiStatus),
    isGetDomainListFailed: isFailed(_store.domains.api.getDomainListApiStatus),
    isGetDomainListSuccess: isSuccess(_store.domains.api.getDomainListApiStatus),
    getDomainListError: getErrorMessage(_store.domains.api.getDomainListApiStatus) || '',
    isManageDomainSuccess: isSuccess(_store.domains.api.manageDomainApiStatus),
    isManageDomainFailed: isFailed(_store.domains.api.manageDomainApiStatus),
    isRemoveDomainPending: isPending(_store.domains.api.deleteDomainApiStatus),
    domainNameFilter: _store.domains.domainNameFilter || '',
    isResetDomainPending: isPending(_store.domains.api.resetDomainSettingsApiStatus),
    isResetDomainSuccess: isSuccess(_store.domains.api.resetDomainSettingsApiStatus),
    appType: _store.app.appType,
    isRemoveDomainMovePending: isPending(_store.domains.api.deleteDomainMoveApiStatus)
  }))
  const { appTypeEntryPath } = useAppTypeEntryPaths()

  const [expandedItemIds, setExpandedItemIds] = useState<string[]>([])
  const mxBannerRef = useRef<HTMLDivElement>(null)
  const isMxMisconfiguredBannerVisible = useMemo(
    () => !!domains.find(d => d.mxStatus === MxStatus.MISCONFIGURED),
    [domains]
  )
  const [isMxBannerPopoverOpen, setIsMxBannerPopoverOpen] = useState(false)
  const [isHelpOpen, setIsHelpOpen] = useState(false)
  const [isAddDomainOpen, setIsAddDomainOpen] = useState(false)
  const [removeDomainData, setRemoveDomainData] = useState<RemoveDomainData>()
  const [manageDomainId, setManageDomainId] = useState<string>()
  const [resetDomainId, setResetDomainId] = useState<string>()

  const tableData = useMemo(() => {
    const { take } = tableConfig
    const { data } = process(domains, { skip: 0, take })

    return {
      data: data
        .filter(item => !!item)
        .map(item => ({
          ...item,
          isExpanded: expandedItemIds.includes(item.domainId),
          hasDomainSpecificSettings: Array.isArray(item.uniqueSettings) && item.uniqueSettings.length > 0,
          emergencyInboxEnabledTextId: item.emergencyInboxEnabled === SettingValue.ENABLED ? 'enabled' : 'disabled',
          isDomainMove: !!item.domainMoveState
        })),
      total: totalDomainCount
    }
  }, [domains, expandedItemIds, tableConfig, totalDomainCount])

  const manageDomainButtonLabel = useMemo(
    () => (appType === AppTypes.helpdesk ? 'actions.helpdesk' : 'actions.manage'),
    [appType]
  )

  const onExpandChange = useCallback(
    (dataItem: ModifiedDomainManagerItem) => {
      const idx = expandedItemIds.findIndex(itemId => itemId === dataItem.domainId)
      if (idx >= 0) {
        expandedItemIds.splice(idx, 1)
      }

      const nextExpendedItemIds = idx === -1 ? [...expandedItemIds, dataItem.domainId] : [...expandedItemIds]
      setExpandedItemIds(nextExpendedItemIds)
    },
    [expandedItemIds]
  )

  const onClickMxBannerLearnMore = useCallback(() => setIsMxBannerPopoverOpen(true), [])

  const onCloseMxBannerPopover = useCallback(() => setIsMxBannerPopoverOpen(false), [])

  const onRowClick = useCallback(
    (event: MouseEvent, dataItem: ModifiedDomainManagerItem) => {
      event.stopPropagation()
      if (event.detail === 1) {
        onExpandChange(dataItem)
      }
    },
    [onExpandChange]
  )

  const onEditDomain = useCallback((e: MouseEvent, domainId: string) => {
    e.stopPropagation()
    routesConfig.EDIT_DOMAIN.goto({ domainId })
  }, [])

  const onManageDomain = useCallback(
    (e: MouseEvent, dataItem: ModifiedDomainManagerItem) => {
      e.stopPropagation()
      setManageDomainId(dataItem.domainId)
      dispatch(manageDomain({ pdDomainId: dataItem.domainId }))
    },
    [dispatch]
  )

  const onRemoveDomain = useCallback((e: MouseEvent, dataItem: ModifiedDomainManagerItem) => {
    e.stopPropagation()
    setRemoveDomainData({ domainId: dataItem.domainId, isDomainMove: dataItem.isDomainMove })
  }, [])

  const onVerifyDomainOwnership = useCallback((e: MouseEvent, dataItem: ModifiedDomainManagerItem) => {
    e.stopPropagation()
    routesConfig.VERIFY_DOMAIN.goto({ domainId: dataItem.domainId })
  }, [])

  const onVerifyDomainOwnershipAndTransfer = useCallback((e: MouseEvent, dataItem: ModifiedDomainManagerItem) => {
    e.stopPropagation()
    routesConfig.VERIFY_DOMAIN_TRANSFER.goto({ domainId: dataItem.domainId })
  }, [])

  const onViewDomainRecipients = useCallback((e: MouseEvent, dataItem: ModifiedDomainManagerItem) => {
    e.stopPropagation()
    routesConfig.UNIQUE_DOMAIN_RECIPIENTS.goto({ domainId: dataItem.domainId })
  }, [])

  const fetchData = useCallback(
    (page: number, size: number, sortField?: string, sortDirection?: 'asc' | 'desc', domainName?: string) => {
      dispatch(
        getDomainList({
          page,
          size,
          sortField,
          sortDirection,
          domainNameFilter: domainName || undefined
        })
      )
    },
    [dispatch]
  )

  const onPageChange = useCallback(
    (e: GridPageChangeEvent) => {
      const { take, skip } = e.page
      const page = take > 0 ? skip / take + 1 : 1
      const size = take > 0 ? take : 10
      const sortField = tableConfig.sort?.[0]?.field || 'domain_name'
      const sortDirection = tableConfig.sort?.[0]?.dir || 'asc'
      dispatch(updateDomainsTable({ skip, take, sort: [{ field: sortField, dir: sortDirection }] }))
      fetchData(page, size, sortField, sortDirection, domainNameFilter)
    },
    [tableConfig.sort, dispatch, fetchData, domainNameFilter]
  )

  const onSortChange = useCallback(
    (e: GridSortChangeEvent) => {
      const { sort } = e
      const { skip, take } = tableConfig
      const page = take > 0 ? skip / take + 1 : 1
      const size = take > 0 ? take : 10
      dispatch(updateDomainsTable({ skip, take, sort }))
      fetchData(page, size, sort[0]?.field, sort[0]?.dir, domainNameFilter)
    },
    [tableConfig, dispatch, fetchData, domainNameFilter]
  )

  const onOpenAddDomain = useCallback(() => setIsAddDomainOpen(true), [])

  const onCloseAddDomain = useCallback(() => setIsAddDomainOpen(false), [])

  const onHelpOpen = useCallback(() => setIsHelpOpen(true), [])

  const onHelpClose = useCallback(() => setIsHelpOpen(false), [])

  const onCloseRemoveDomain = useCallback(() => setRemoveDomainData(undefined), [])

  const onChangeDomainNameFilter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(setDomainNameFilter(e.target.value))
    },
    [dispatch]
  )

  const onSearchDomain = useCallback(() => {
    const { take, sort } = tableConfig
    const sortField = sort?.[0]?.field || 'domain_name'
    const sortDirection = sort?.[0]?.dir || 'asc'
    dispatch(updateDomainsTable({ skip: 0 }))
    fetchData(1, take, sortField, sortDirection, domainNameFilter)
  }, [dispatch, domainNameFilter, fetchData, tableConfig])

  const onOpenGlobalResetConfirmDialog = useCallback((e: MouseEvent, dataItem: ModifiedDomainManagerItem) => {
    e.stopPropagation()
    setResetDomainId(dataItem.domainId)
  }, [])

  const onCloseGlobalResetConfirmDialog = useCallback(() => {
    setResetDomainId(undefined)
  }, [])

  const onConfirmResetDomainToAccountPolicies = useCallback(() => {
    if (resetDomainId) {
      dispatch(resetDomainSettings({ domainId: resetDomainId }))
    }
  }, [dispatch, resetDomainId])

  useEffect(() => {
    trackEventInAllServices(TRACKING_EVENTS.WEBUI.DOMAINS_PAGE_VIEW)
    dispatch(resetEditDomain())
    dispatch(getDomainList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isManageDomainSuccess) {
      setManageDomainId(undefined)
      appTypeEntryPath.goto()
    }
  }, [isManageDomainSuccess, appTypeEntryPath])

  useEffect(() => {
    if (isManageDomainFailed) {
      setManageDomainId(undefined)
    }
  }, [isManageDomainFailed])

  useEffect(() => {
    if (isResetDomainSuccess) {
      setResetDomainId(undefined)
    }
  }, [isResetDomainSuccess])

  // Handle domain transfers: start / stop polling for domain transfers
  useEffect(() => {
    const pendingTransfers = (domains || [])
      .filter(d => d.domainMoveState === DomainMoveState.TRANSFER_PENDING)
      .map(d => d.domainId)
    if (pendingTransfers.length === 0) {
      poll.stopAll()
      return
    }
    // Stop polling completed transfers
    const transfersToStop = polledDomainTransfers.current.filter(id => !pendingTransfers.includes(id))
    poll.stop(transfersToStop)

    // Start polling pending transfers
    const transfersToAdd = pendingTransfers.filter(id => !polledDomainTransfers.current.includes(id))
    poll.add(transfersToAdd)
    // eslint-disable-next-line
  }, [domains])

  // Handle domain transfer poll result: mark completed transfers in domain list and stop polling for the given ID(s)
  useEffect(() => {
    Object.keys(domainTransferStates).forEach(id => {
      if (domainTransferStates[parseInt(id, 10)] === null) {
        poll.stop([id])
        dispatch(setDomainTransferComplete(id))
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainTransferStates])

  return useMemo(
    () => [
      {
        page: {
          isPending: !!removeDomainData || !!manageDomainId
        },
        table: {
          isLoading: isGetDomainListPending,
          isSuccess: isGetDomainListSuccess,
          isFailed: isGetDomainListFailed,
          config: tableConfig,
          data: tableData.data,
          total: tableData.total,
          loadedAndNoRecord: isGetDomainListSuccess && tableData.data.length === 0,
          error: getDomainListError
        },
        mxBanner: {
          isVisible: isMxMisconfiguredBannerVisible,
          ref: mxBannerRef,
          isPopoverOpen: isMxBannerPopoverOpen
        },
        help: {
          isHelpOpen
        },
        addDomain: {
          isAddDomainOpen
        },
        removeDomain: {
          isRemoveDomainOpen: !!removeDomainData,
          removeDomainData,
          isRemoveDomainPending,
          isRemoveDomainMovePending
        },
        manageDomain: {
          buttonLabel: manageDomainButtonLabel,
          domainId: manageDomainId
        },
        searchDomain: {
          domainNameFilter
        },
        globalReset: {
          isOpen: !!resetDomainId,
          isPending: isResetDomainPending
        },
        permissions
      },
      {
        onRowClick,
        mxBanner: {
          onClickLearnMore: onClickMxBannerLearnMore,
          onClosePopover: onCloseMxBannerPopover
        },
        onEditDomain,
        onManageDomain,
        onRemoveDomain,
        onVerifyDomainOwnership,
        onVerifyDomainOwnershipAndTransfer,
        onViewDomainRecipients,
        onPageChange,
        onSortChange,
        help: {
          onHelpOpen,
          onHelpClose
        },
        addDomain: {
          onOpenAddDomain,
          onCloseAddDomain
        },
        removeDomain: {
          onCloseRemoveDomain
        },
        searchDomain: {
          onChangeDomainNameFilter,
          onSearchDomain
        },
        globalReset: {
          onOpenConfirmDialog: onOpenGlobalResetConfirmDialog,
          onCloseConfirmDialog: onCloseGlobalResetConfirmDialog,
          onConfirmReset: onConfirmResetDomainToAccountPolicies
        }
      }
    ],
    [
      removeDomainData,
      manageDomainId,
      manageDomainButtonLabel,
      isGetDomainListPending,
      isGetDomainListSuccess,
      isGetDomainListFailed,
      tableConfig,
      tableData.data,
      tableData.total,
      getDomainListError,
      isMxMisconfiguredBannerVisible,
      isMxBannerPopoverOpen,
      isHelpOpen,
      isAddDomainOpen,
      isRemoveDomainPending,
      domainNameFilter,
      resetDomainId,
      isResetDomainPending,
      onRowClick,
      onClickMxBannerLearnMore,
      onCloseMxBannerPopover,
      onEditDomain,
      onManageDomain,
      onRemoveDomain,
      onVerifyDomainOwnership,
      onVerifyDomainOwnershipAndTransfer,
      onViewDomainRecipients,
      onPageChange,
      onSortChange,
      onHelpOpen,
      onHelpClose,
      onOpenAddDomain,
      onCloseAddDomain,
      onCloseRemoveDomain,
      onChangeDomainNameFilter,
      onSearchDomain,
      onOpenGlobalResetConfirmDialog,
      onCloseGlobalResetConfirmDialog,
      onConfirmResetDomainToAccountPolicies,
      permissions,
      isRemoveDomainMovePending
    ]
  )
}
