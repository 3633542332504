import { makeStyles, colors } from '@barracuda-internal/bds-core'

export const PROGRESS_WIDTH = 24
export const PROGRESS_HEIGHT = 23

export default makeStyles(theme => ({
  headerWrapper: {
    padding: theme.spacing(1, 2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerTitle: {
    display: 'flex',
    alignItem: 'center'
  },
  helpIcon: {
    cursor: 'pointer',
    marginLeft: theme.spacing(0.5),
    width: theme.spacing(2.5),
    marginTop: theme.spacing(0.25),
    color: colors.blue600
  },
  cardsRow: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 0)
  },
  countWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(6)
  },
  count: {
    marginRight: theme.spacing(1),
    fontSize: 45,
    '&.red': {
      color: colors.red600
    },
    '&.orange': {
      color: colors.redOrange400
    }
  },
  countSubtitle: {
    color: colors.scaleGray500
  },
  container: {
    padding: theme.spacing(0, 2)
  },
  domainSelectWrapper: {
    marginTop: theme.spacing(2)
  },
  selectInput: {
    '& > div:first-child': {
      backgroundColor: colors.white,
      minWidth: '160px',
      paddingTop: '14px',
      paddingBottom: '14px'
    }
  },
  exportButton: {
    marginBottom: theme.spacing(3)
  },
  searchToolWrapper: {
    margin: theme.spacing(0, 2, 2, 0)
  },
  searchTextField: {
    margin: theme.spacing(0, 2, 2, 0),
    backgroundColor: theme.palette.common.white,
    minWidth: 624
  },
  selectMenus: {
    backgroundColor: theme.palette.common.white,
    fontSize: '14px',
    minWidth: '125px',
    padding: theme.spacing(0, 1),
    '& > div:focus': {
      background: 'inherit'
    },
    '&:before': {
      // normal
      borderBottom: 'none !important'
    },
    '&:after': {
      // focused
      borderBottom: 'none !important'
    },
    '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
      // hover
      borderBottom: 'none !important'
    }
  },
  selectMenusWide: {
    minWidth: '160px'
  },
  logTableProgress: {
    height: '3px'
  },
  progressIcon: {
    width: theme.spacing(1),
    height: theme.spacing(1)
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  statusIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    '&.clean': {
      color: colors.green300
    },
    '&.infected': {
      color: colors.red500
    },
    '&.suspicious': {
      color: colors.orange300
    },
    '&.error': {
      color: colors.orange400
    }
  },
  statusText: {
    marginLeft: theme.spacing(2)
  },
  linkText: {
    color: colors.blue600
  }
}))
