import { makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  advancedSearchForm: {
    marginTop: theme.spacing(1.25),
    minHeight: 320
  },
  buttonsWrapper: {
    justifyContent: 'space-between',
    '& :first-child > button': {
      marginRight: theme.spacing(2)
    },
    '& :last-child > button': {
      marginLeft: theme.spacing(2)
    }
  },
  actions: {
    paddingTop: 8,
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16
  },
  dateTimePickerWrapper: {
    marginBottom: theme.spacing(2),
    whiteSpace: 'nowrap',
    '& > :first-child': {
      marginRight: 16
    }
  },
  dateTimePicker: {
    backgroundColor: theme.palette.common.white,
    minWidth: 218,
    padding: theme.spacing(0, 1),
    '& .k-picker-wrap.k-state-default': {
      borderStyle: 'none !important'
    },
    '& .k-picker-wrap input': {
      fontSize: '14px'
    }
  }
}))
