import React, { useMemo } from 'react'
import { DateTimePicker } from '@progress/kendo-react-dateinputs'

import {
  Button,
  Card,
  TextField,
  CardContent,
  CardActions,
  Typography,
  Grid,
  ClickAwayListener
} from '@barracuda-internal/bds-core'
import { Folder, Save } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { useFormatMessage } from 'lib/localization'

import OutlinedDiv from 'components/libs/outlinedDiv/OutlinedDiv'
import SaveSearchDialog from 'components/libs/dialog/saveSearch/SaveSearchDialog'
import OpenSearchDialog from 'components/libs/dialog/openSearch/OpenSearchDialog'
import {
  AdvancedSearchProps,
  useAdvancedSearchLogic
} from 'components/pages/messageLog/messageLogSearchBar/advancedSearch/useAdvancedSearchLogic'
import styles from 'components/pages/messageLog/messageLogSearchBar/advancedSearch/advancedSearchStyles'

const BASE_I18N_KEY = 'ess.advanced_search'
const BASE_I18N_DIALOG_KEY = 'ess.dialog'

const AdvancedSearch: React.FC<AdvancedSearchProps> = props => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const dialogFormatMessage = useFormatMessage(BASE_I18N_DIALOG_KEY)

  const {
    formData,
    handleFormChange,
    handleClose,
    setRevertOnCancel,
    handleSearch,
    datePickerConfig,
    start,
    end,
    isSaveSearchOpen,
    setIsSaveSearchOpen,
    onClickAway,
    isOpenSearchOpen,
    setIsOpenSearchOpen,
    viewConfig
  } = useAdvancedSearchLogic(props)

  return useMemo(
    () => (
      <>
        <ClickAwayListener onClickAway={onClickAway}>
          <form noValidate autoComplete="off">
            <Card elevation={1} data-testid="root-card">
              <CardContent className={classes.advancedSearchForm}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body1">{formatMessage('keywords')}</Typography>
                    <TextField
                      variant="outlined"
                      onChange={handleFormChange}
                      name="basic_search_term"
                      value={formData.basic_search_term}
                      fullWidth
                      inputProps={{ style: { padding: 8 } }}
                      data-testid="search-input"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">{formatMessage('from')}</Typography>
                    <TextField
                      variant="outlined"
                      onChange={handleFormChange}
                      name="header_from"
                      value={formData.header_from}
                      fullWidth
                      inputProps={{ style: { padding: 8 } }}
                      data-testid="header-from-input"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">{formatMessage('envelope_from')}</Typography>
                    <TextField
                      variant="outlined"
                      onChange={handleFormChange}
                      name="envelope_from"
                      value={formData.envelope_from}
                      fullWidth
                      inputProps={{ style: { padding: 8 } }}
                      data-testid="envelope-from-input"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">{formatMessage('to')}</Typography>
                    <TextField
                      variant="outlined"
                      onChange={handleFormChange}
                      name="header_to"
                      value={formData.header_to}
                      fullWidth
                      inputProps={{ style: { padding: 8 } }}
                      data-testid="header-to-input"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">{formatMessage('envelope_to')}</Typography>
                    <TextField
                      variant="outlined"
                      onChange={handleFormChange}
                      name="envelope_to"
                      value={formData.envelope_to}
                      fullWidth
                      inputProps={{ style: { padding: 8 } }}
                      data-testid="envelope-to-input"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">{formatMessage('subject')}</Typography>
                    <TextField
                      variant="outlined"
                      onChange={handleFormChange}
                      name="subject"
                      value={formData.subject}
                      fullWidth
                      inputProps={{ style: { padding: 8 } }}
                      data-testid="subject-input"
                    />
                  </Grid>
                  {viewConfig.advancedSearch.showAttachmentName && (
                    <Grid item xs={12}>
                      <Typography variant="body1">{formatMessage('attachment_name')}</Typography>
                      <TextField
                        variant="outlined"
                        onChange={handleFormChange}
                        name="attachment"
                        value={formData.attachment}
                        fullWidth
                        inputProps={{ style: { padding: 8 } }}
                        data-testid="attachment-input"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <div className={classes.dateTimePickerWrapper}>
                      <OutlinedDiv label={formatMessage('start_date')} data-testid="start-datepicker">
                        <DateTimePicker
                          className={classes.dateTimePicker}
                          name="start"
                          value={start}
                          {...datePickerConfig}
                        />
                      </OutlinedDiv>
                      <OutlinedDiv label={formatMessage('end_date')} data-testid="end-datepicker">
                        <DateTimePicker
                          className={classes.dateTimePicker}
                          name="end"
                          value={end}
                          {...datePickerConfig}
                        />
                      </OutlinedDiv>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions className={classes.actions}>
                <Grid container className={classes.buttonsWrapper}>
                  <Grid item>
                    <Button
                      data-id="adv-search-save-button"
                      variant="text"
                      color="secondary"
                      onClick={() => {
                        setRevertOnCancel(false)
                        setIsSaveSearchOpen(true)
                      }}
                      startIcon={<Save />}
                      data-testid="save-button"
                    >
                      {dialogFormatMessage('save')}
                    </Button>
                    <Button
                      data-id="saved-search-open-button"
                      variant="text"
                      color="secondary"
                      onClick={() => {
                        setRevertOnCancel(false)
                        setIsOpenSearchOpen(true)
                      }}
                      startIcon={<Folder />}
                      data-testid="open-button"
                    >
                      {dialogFormatMessage('open')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      data-id="adv-search-cancel-button"
                      variant="text"
                      color="primary"
                      onClick={handleClose}
                      data-testid="cancel-button"
                    >
                      {dialogFormatMessage('cancel')}
                    </Button>
                    <Button
                      data-id="adv-search-button"
                      variant="contained"
                      color="primary"
                      onClick={handleSearch}
                      data-testid="search-button"
                    >
                      {dialogFormatMessage('search')}
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </form>
        </ClickAwayListener>
        {isSaveSearchOpen && <SaveSearchDialog onClose={() => setIsSaveSearchOpen(false)} />}
        {isOpenSearchOpen && <OpenSearchDialog onClose={() => setIsOpenSearchOpen(false)} />}
      </>
    ),
    [
      classes,
      formatMessage,
      dialogFormatMessage,
      formData,
      handleFormChange,
      handleClose,
      setRevertOnCancel,
      handleSearch,
      datePickerConfig,
      start,
      end,
      isSaveSearchOpen,
      setIsSaveSearchOpen,
      onClickAway,
      isOpenSearchOpen,
      setIsOpenSearchOpen,
      viewConfig
    ]
  )
}

export default AdvancedSearch
