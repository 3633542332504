import React, { useMemo } from 'react'
import { CSVLink } from 'react-csv'

import {
  Box,
  Button,
  CircularProgress,
  DataTable,
  DataTableColumn,
  Grid,
  IconButton,
  LinearProgress,
  Select,
  SelectMenuItem,
  SelectWrapper,
  SelectLabel,
  TextField,
  Typography,
  MenuItem
} from '@barracuda-internal/bds-core'
import { Search } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { GridNoRecords } from '@progress/kendo-react-grid'
import OutlinedDiv from 'components/libs/outlinedDiv/OutlinedDiv'

import { useFormatMessage } from 'lib/localization'
import { onKeyDown } from 'lib/keyEvents'

import { Cell } from 'components/libs/grid/cell'
import { TableText } from 'components/libs/layout/pages/TableText'

import styles, { PROGRESS_HEIGHT, PROGRESS_WIDTH } from 'components/pages/atpLog/atpLogStyles'
import { useAtpLogLogic } from 'components/pages/atpLog/useAtpLogLogic'
import { ModifiedAtpLog } from 'types/Atp'
import AtpLogSupportPage from 'components/pages/support/help/atpLog/AtpLog'
import { PageContainer, PageTitle } from 'components/libs/layout/pages/pageLayout'
import ContentLoaderMessage from 'components/libs/contentLoader/ContentLoaderMessage'
import ContentLoader from 'components/libs/contentLoader/ContentLoader'
import { GROUP_TITLE, TOPICS } from '../support/config'

const BASE_I18N_KEY = 'ess.atp_log'
const BASE_I18N_EXPORT_KEY = 'ess.atp_log.export'
const BASE_I18N_TABLE_KEY = 'ess.data_tables.atp_log'

const AtpLog = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatExportMessage = useFormatMessage(BASE_I18N_EXPORT_KEY)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)

  const {
    inProgress,
    isSuccess,
    isFailed,
    isLogInProgress,
    isOverviewInProgress,
    loadedAndNoRecord,
    domainConfig,
    infectedCount,
    suspiciousCount,
    exportConfig,
    searchConfig,
    filterConfig,
    tableConfig,
    handleRowClick,
    reportConfig,
    helpConfig
  } = useAtpLogLogic()

  return useMemo(() => {
    const CardCount = ({
      value,
      title,
      subtitle,
      color
    }: {
      value: number
      title: string
      subtitle: string
      color: string
    }) => (
      <Grid className={classes.countWrapper} item data-testid={`${title}-card`}>
        {/* If any calls to the server fails we display the whole content failed to load page */}
        <ContentLoader isLoading={isOverviewInProgress} isFailed={false}>
          <Typography className={`${classes.count} ${color}`} align="center" data-testid={`${title}-value`}>
            {value}
          </Typography>
          <Grid>
            <Typography variant="subtitle1" data-testid={`${title}-title`}>
              {formatMessage(title)}
            </Typography>
            <Typography className={classes.countSubtitle} variant="body2" data-testid={`${title}-subtitle`}>
              {formatMessage(subtitle)}
            </Typography>
          </Grid>
        </ContentLoader>
      </Grid>
    )

    return (
      <>
        <CSVLink
          ref={exportConfig.exportRef}
          filename={exportConfig.fileName()}
          headers={exportConfig.headers.map(header => ({ ...header, label: formatExportMessage(header.label) }))}
          data={tableConfig.tableData.data}
          data-testid="export-link"
        />

        <Grid>
          <PageContainer>
            <PageTitle
              title={formatMessage('title')}
              onOpenSupport={helpConfig.onHelpClick}
              help={{
                content: AtpLogSupportPage,
                onClose: helpConfig.onCloseHelp,
                title: TOPICS.atpLog,
                group: GROUP_TITLE.messageLog,
                isHelpOpen: helpConfig.isOpen
              }}
            >
              {isSuccess && (
                <SelectWrapper className={classes.domainSelectWrapper} variant="outlined">
                  <SelectLabel>{formatMessage('domain')}</SelectLabel>
                  <Select
                    className={classes.selectInput}
                    label={formatMessage('domain')}
                    onChange={domainConfig.onSelect}
                    value={domainConfig.selectedItem}
                    disabled={domainConfig.disabled}
                    data-testid="select-domain-menu"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center'
                      },
                      transformOrigin: {
                        vertical: -5,
                        horizontal: 'center'
                      }
                    }}
                  >
                    {domainConfig.items.map(({ value, label }) => (
                      <SelectMenuItem key={label} value={value} data-testid={`${value}-domain-action`}>
                        {label === 'all_domains' ? formatMessage(label) : label}
                      </SelectMenuItem>
                    ))}
                  </Select>
                </SelectWrapper>
              )}
            </PageTitle>
          </PageContainer>

          {inProgress && <LinearProgress data-testid="linear-loader" />}
          {isFailed && <ContentLoaderMessage />}
          {isSuccess && (
            <Grid item xs={12} className={classes.container}>
              <Grid className={classes.cardsRow}>
                <CardCount
                  value={infectedCount}
                  title="infected_attachments"
                  subtitle="last_30_days"
                  color="red"
                  data-testid="infected-card"
                />
                <CardCount
                  value={suspiciousCount}
                  title="suspicious_attachments"
                  subtitle="last_30_days"
                  color="orange"
                  data-testid="suspicious-card"
                />
              </Grid>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item className={classes.searchToolWrapper}>
                  <TextField
                    className={classes.searchTextField}
                    placeholder={formatMessage('search_placeholder')}
                    variant="outlined"
                    value={searchConfig.search}
                    onChange={searchConfig.handleInputChange}
                    onKeyDown={onKeyDown(['Enter'], () => searchConfig.fetchData())}
                    InputProps={{
                      endAdornment: (
                        <IconButton size="small" edge="start" data-id="search-button" data-testid="search-button">
                          <Search />
                        </IconButton>
                      )
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{ style: { paddingTop: 14, paddingBottom: 14, paddingRight: 4, paddingLeft: 10 } }}
                    data-testid="search-input"
                  />
                  {/* status */}
                  <OutlinedDiv label={formatMessage('status')}>
                    <Select
                      className={`${classes.selectMenus} ${classes.selectMenusWide}`}
                      name="status"
                      data-id="status-filter"
                      value={filterConfig.selectedStatusFilter}
                      onChange={filterConfig.handleFilterChange}
                      data-testid="status-filter"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'center'
                        },
                        transformOrigin: {
                          vertical: -5,
                          horizontal: 'center'
                        }
                      }}
                    >
                      {filterConfig.statusFilters.map(value => (
                        <MenuItem key={value} value={value}>
                          {formatMessage(`filters.${value}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </OutlinedDiv>
                  {/* time */}
                  <OutlinedDiv label={formatMessage('time')}>
                    <Select
                      className={`${classes.selectMenus} ${classes.selectMenusWide}`}
                      name="time"
                      data-id="time-filter"
                      value={filterConfig.selectedTimeFilter}
                      onChange={filterConfig.handleFilterChange}
                      data-testid="time-filter"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'center'
                        },
                        transformOrigin: {
                          vertical: -5,
                          horizontal: 'center'
                        }
                      }}
                    >
                      {filterConfig.timeFilters.map(value => (
                        <MenuItem key={value} value={value}>
                          {formatMessage(`filters.${value}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </OutlinedDiv>
                  {/* Filter type */}
                  <OutlinedDiv label={formatMessage('file_type')}>
                    <Select
                      className={`${classes.selectMenus} ${classes.selectMenusWide}`}
                      name="filetype"
                      data-id="filetype-filter"
                      value={filterConfig.selectedFiletype}
                      onChange={filterConfig.handleFilterChange}
                      data-testid="select-filetype-menu"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'center'
                        },
                        transformOrigin: {
                          vertical: -5,
                          horizontal: 'center'
                        }
                      }}
                    >
                      {filterConfig.filetypes.map(type => (
                        <MenuItem key={type.key} value={type.key}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </OutlinedDiv>
                </Grid>
                <Grid item className={classes.exportButton}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={exportConfig.csvExport}
                    data-testid="export-button"
                  >
                    {formatMessage('export_button')}
                  </Button>
                </Grid>
              </Grid>
              <Box className={classes.logTableProgress}>
                {isLogInProgress && <LinearProgress data-testid="linear-loader" />}
              </Box>
              <DataTable
                pageConfig={{
                  pageable: { pageSizes: [10, 25, 50] },
                  skip: tableConfig.tableData.skip,
                  take: tableConfig.tableData.take,
                  total: tableConfig.tableData.total
                }}
                onPageChange={searchConfig.handlePageChange}
                data={tableConfig.tableData}
                selectedField="selected"
                resizable
              >
                <GridNoRecords>{loadedAndNoRecord ? tableFormatMessage('no_records_available') : ' '}</GridNoRecords>
                <DataTableColumn
                  field={tableConfig.columns.STATUS}
                  {...tableConfig.columnsConfig[tableConfig.columns.STATUS]}
                  title={tableFormatMessage(`${tableConfig.columns.STATUS}`)}
                  cell={({ dataItem }: { dataItem: ModifiedAtpLog }) => (
                    <Cell data-testid="status-cell" onClick={() => handleRowClick(dataItem)}>
                      {dataItem.isFuture && (
                        <Grid className={classes.statusWrapper}>
                          <CircularProgress
                            style={{ height: PROGRESS_HEIGHT, width: PROGRESS_WIDTH }}
                            className={classes.progressIcon}
                            color="secondary"
                          />
                          <Typography className={classes.statusText} variant="body2">
                            {dataItem.statusText}
                          </Typography>
                        </Grid>
                      )}
                      {!dataItem.isFuture && (
                        <Grid className={classes.statusWrapper}>
                          {dataItem.statusIcon && (
                            <dataItem.statusIcon className={`${classes.statusIcon} ${dataItem.statusText}`} />
                          )}
                          {!dataItem.statusIcon && <Grid className={classes.statusIcon} />}
                          <Typography className={classes.statusText} variant="body2">
                            {dataItem.statusText}
                          </Typography>
                        </Grid>
                      )}
                    </Cell>
                  )}
                />
                <DataTableColumn
                  field={tableConfig.columns.TIME}
                  {...tableConfig.columnsConfig[tableConfig.columns.TIME]}
                  title={tableFormatMessage(`${tableConfig.columns.TIME}`)}
                  cell={({ dataItem }: { dataItem: ModifiedAtpLog }) => (
                    <Cell data-testid="time-cell" onClick={() => handleRowClick(dataItem)}>
                      <TableText dataText={dataItem.formattedDate} truncated />
                    </Cell>
                  )}
                />
                <DataTableColumn
                  field={tableConfig.columns.FROM}
                  {...tableConfig.columnsConfig[tableConfig.columns.FROM]}
                  title={tableFormatMessage(`${tableConfig.columns.FROM}`)}
                  cell={({ dataItem }: { dataItem: ModifiedAtpLog }) => (
                    <Cell data-testid="from-cell" onClick={() => handleRowClick(dataItem)}>
                      <TableText dataText={dataItem.headerFrom} truncated />
                    </Cell>
                  )}
                />
                <DataTableColumn
                  field={tableConfig.columns.TO}
                  {...tableConfig.columnsConfig[tableConfig.columns.TO]}
                  title={tableFormatMessage(`${tableConfig.columns.TO}`)}
                  cell={({ dataItem }: { dataItem: ModifiedAtpLog }) => (
                    <Cell data-testid="to-cell" onClick={() => handleRowClick(dataItem)}>
                      <TableText dataText={dataItem.headerTo} truncated />
                    </Cell>
                  )}
                />
                <DataTableColumn
                  field={tableConfig.columns.SUBJECT}
                  {...tableConfig.columnsConfig[tableConfig.columns.SUBJECT]}
                  title={tableFormatMessage(`${tableConfig.columns.SUBJECT}`)}
                  cell={({ dataItem }: { dataItem: ModifiedAtpLog }) => (
                    <Cell data-testid="subject-cell" onClick={() => handleRowClick(dataItem)}>
                      <TableText dataText={dataItem.headerSubject} truncated />
                    </Cell>
                  )}
                />
                <DataTableColumn
                  field={tableConfig.columns.FILE_NAME}
                  {...tableConfig.columnsConfig[tableConfig.columns.FILE_NAME]}
                  title={tableFormatMessage(`${tableConfig.columns.FILE_NAME}`)}
                  cell={({ dataItem }: { dataItem: ModifiedAtpLog }) => (
                    <Cell data-testid="file-name-cell" onClick={() => handleRowClick(dataItem)}>
                      <TableText dataText={dataItem.filename} truncated />
                    </Cell>
                  )}
                />
                <DataTableColumn
                  field={tableConfig.columns.FILE_TYPE}
                  {...tableConfig.columnsConfig[tableConfig.columns.FILE_TYPE]}
                  title={tableFormatMessage(`${tableConfig.columns.FILE_TYPE}`)}
                  cell={({ dataItem }: { dataItem: ModifiedAtpLog }) => (
                    <Cell data-testid="file-type-cell" onClick={() => handleRowClick(dataItem)}>
                      <TableText dataText={dataItem.fileType} truncated />
                    </Cell>
                  )}
                />
                <DataTableColumn
                  field={tableConfig.columns.REPORT}
                  {...tableConfig.columnsConfig[tableConfig.columns.REPORT]}
                  title={tableFormatMessage(`${tableConfig.columns.REPORT}`)}
                  cell={({ dataItem }: { dataItem: ModifiedAtpLog }) => (
                    <Cell data-testid="report-cell">
                      <Button
                        size="small"
                        variant="text"
                        color="primary"
                        disabled={reportConfig.isLoading}
                        onClick={() => reportConfig.onViewReport(dataItem)}
                        data-testid="report-button"
                      >
                        <Typography variant="body2">{tableFormatMessage('view_report')}</Typography>
                      </Button>
                    </Cell>
                  )}
                />
              </DataTable>
            </Grid>
          )}
        </Grid>
      </>
    )
  }, [
    classes,
    formatMessage,
    formatExportMessage,
    tableFormatMessage,
    domainConfig,
    infectedCount,
    suspiciousCount,
    exportConfig,
    searchConfig,
    filterConfig,
    tableConfig,
    handleRowClick,
    reportConfig,
    inProgress,
    helpConfig,
    isFailed,
    isLogInProgress,
    isOverviewInProgress,
    isSuccess,
    loadedAndNoRecord
  ])
}

export default AtpLog
