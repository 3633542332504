import { useEffect, useMemo } from 'react'

import { useAppSelector } from 'redux/toolkit/hooks'
import useAppTypeEntryPaths from 'components/libs/routes/useAppTypeEntryPaths'

export enum UiState {
  INITIALIZING,
  READY
}

export interface UseEmailLogLogic {
  uiState: UiState
}

export const useEmailLogLogic = (): UseEmailLogLogic => {
  const { appTypeEntryPath } = useAppTypeEntryPaths()
  const { isEmailLogEnabled } = useAppSelector(_stores => ({
    isEmailLogEnabled: _stores.auth.accessTokenObject?.features?.isEmailLogEnabled
  }))

  const uiState = useMemo(() => {
    switch (true) {
      case typeof isEmailLogEnabled !== 'undefined':
        return UiState.READY
      default:
        return UiState.INITIALIZING
    }
  }, [isEmailLogEnabled])

  useEffect(() => {
    if (isEmailLogEnabled === false) {
      appTypeEntryPath.goto()
    }
  }, [appTypeEntryPath, isEmailLogEnabled])

  return useMemo(
    () => ({
      uiState
    }),
    [uiState]
  )
}
