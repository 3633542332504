import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { GridPageChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid'
import { process } from '@progress/kendo-data-query'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import toggleInArray from 'lib/toggleInArray'
import { DomainsInDB } from 'types/redux/user/UserTypes'
import { UserType } from 'types/auth'
import { getAccountUsers, resetAccountUsers } from 'redux/features/users/usersSlice'
import { getAvailableDomains } from 'redux/features/user/userSlice'
import { AccountUser, AccountUsersResults, LinkedAccount } from 'types/users'
import { update as updateUserTableConfig, UsersTableState } from 'redux/features/dataTables/users/usersSlice'
import {
  IsAllCheckboxSelected,
  IsCheckboxIdIsSelected,
  OnSelectAllCheckboxes,
  OnSelectCheckbox,
  SelectedCheckboxes,
  useDatatableCheckboxColumnLogic
} from 'lib/useDatatableCheckboxColumnLogic'
import { FormAlert } from 'components/pages/users/usersList/usersListTypes'
import { useRequestResetPasswordLogic } from 'components/pages/users/usersList/useRequestResetPasswordLogic'
import { loginAsUser } from 'redux/features/users/usersApiThunks'
import { setErrorSnackBar } from 'redux/features/app/appSlice'
import { trackEventInAllServices, TRACKING_EVENTS } from 'lib/monitoring/monitoringService'
import { useUsersRights } from 'components/libs/userRights/pages/useUsersRights'

export interface ModifiedAccountUser extends AccountUser {
  linkedAccountsString: string
}

export enum UserActions {
  edit,
  resetPassword,
  logInAs,
  delete
}

export interface State {
  isInitialLoad: boolean
  tableConfig: UsersTableState
  isGetUsersListInProgress: boolean
  search: string
  domains?: DomainsInDB[]
  accountUsers?: AccountUsersResults
  tableData: {
    total: number
    data: ModifiedAccountUser[]
  }
  selectedUserIds: SelectedCheckboxes
  isUserIdSelected: IsCheckboxIdIsSelected
  isAllUserIdSelected: IsAllCheckboxSelected
  expandedRows: string[]
  userActionStates: {
    [UserActions.edit]: boolean
    [UserActions.resetPassword]: (userType: UserType) => boolean
    [UserActions.logInAs]: boolean
    [UserActions.delete]: boolean
  }
  selectedUserForEdit?: AccountUser
  selectedUsersForDelete?: string[]
  selectedUserForLoginAs?: AccountUser
  formAlert?: FormAlert
  isRequestResetPasswordInProgress: boolean
  isBulkDeleteUsersButtonEnabled: boolean
  isAddUpdateUsersButtonDisabled: boolean
  isDomainsSelectDisabled: boolean
  canDeleteAccountUser: boolean
  requestedResetPasswordUserId?: string
}

export interface EventHandlers {
  onChangeTableConfig: (
    filterName: keyof UsersTableState,
    withReset?: boolean
  ) => (e: ChangeEvent<{ value: unknown }>) => void
  onSearch: () => void
  onChangeSearch: (e: ChangeEvent<{ value: unknown }>) => void
  onResetSearch: () => void
  onPageChange: (e: GridPageChangeEvent) => void
  onSortChange: (e: GridSortChangeEvent) => void
  onSelectUserId: OnSelectCheckbox
  onSelectAllUserIds: OnSelectAllCheckboxes
  onToggleRowExpand: (userId: string) => void
  onSelectedUserForEdit: (user?: AccountUser) => void
  onSelectedUserForDelete: (user?: AccountUser) => void
  onDeleteSelectedUsers: () => void
  onResetPassword: (user: AccountUser) => void
  onLoginAsUser: (user: AccountUser) => void
  resetAlert: Dispatch<SetStateAction<FormAlert | undefined>>
}

export type UseUsersListLogic = [State, EventHandlers]
export const useUsersListLogic = (): UseUsersListLogic => {
  const {
    isGetUsersListInProgress,
    isGetAvailableDomainsSuccess,
    isGetAvailableDomainsFailed,
    isLoginAsUserIsFailed,
    tableConfig,
    domains,
    accountUsers,
    loggedInUser,
    requestedResetPasswordUserId
  } = useAppSelector(_store => ({
    isGetUsersListInProgress: isPending(_store.users.api.getAccountUsersApiStatus),
    isLoginAsUserIsFailed: isFailed(_store.users.api.loginAsUserApiStatus),
    isGetAvailableDomainsSuccess: isSuccess(_store.user.api.getAvailableDomainsApiStatus),
    isGetAvailableDomainsFailed: isSuccess(_store.user.api.getAvailableDomainsApiStatus),
    tableConfig: _store.dataTables.users,
    domains: _store.user.availableDomains,
    accountUsers: _store.users.accountUsers,
    loggedInUser: _store.auth.accessTokenObject,
    requestedResetPasswordUserId: _store.users.requestedResetPasswordUserId
  }))
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true)
  const [search, setSearch] = useState<string>('')
  const [expandedRows, setExpandedRows] = useState<string[]>([])
  const [selectedUserForEdit, setSelectedUserForEdit] = useState<AccountUser | undefined>()
  const [selectedUsersForDelete, setSelectedUsersForDelete] = useState<string[] | undefined>()
  const [selectedUserForLoginAs, setSelectedUserForLoginAs] = useState<AccountUser | undefined>()
  const [formAlert, setFormAlert] = useState<FormAlert | undefined>()
  const isPaginated = useRef<boolean>(false)
  const dispatch = useAppDispatch()
  const [
    selectedUserIds,
    onSelectUserId,
    onSelectAllUserIds,
    isUserIdSelected,
    isAllUserIdSelected,
    getSelectedIds,
    resetSelectedUserIds
  ] = useDatatableCheckboxColumnLogic()
  const [isRequestResetPasswordInProgress, onRequestResetPassword] = useRequestResetPasswordLogic({ setFormAlert })
  const {
    helpers: { pdDomainName },
    canEditAccountUser,
    canResetAccountUserPassword,
    canLoginAsAnAccountUser,
    canDeleteAccountUser,
    canAddUpdateUsers
  } = useUsersRights()

  // init
  useEffect(() => {
    dispatch(getAvailableDomains())
    trackEventInAllServices(TRACKING_EVENTS.ADMIN.USERS_LIST_PAGE_VIEW)

    if (pdDomainName) {
      dispatch(
        updateUserTableConfig({
          domainFilter: pdDomainName
        })
      )
    }

    return () => {
      dispatch(resetAccountUsers())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // manage the initial load
  useEffect(() => {
    if ((isGetAvailableDomainsSuccess || isGetAvailableDomainsFailed) && isInitialLoad) {
      setIsInitialLoad(false)
    }
  }, [isGetAvailableDomainsSuccess, isGetAvailableDomainsFailed, isInitialLoad])

  // reload account user if the config is changed
  useEffect(() => {
    if (!isInitialLoad) {
      if (tableConfig.skip === 0 && !isPaginated.current) {
        dispatch(resetAccountUsers())
      }
      isPaginated.current = false
      dispatch(getAccountUsers())
    }
  }, [tableConfig, dispatch, isInitialLoad])

  // login as user is failed
  useEffect(() => {
    if (isLoginAsUserIsFailed && selectedUserForLoginAs) {
      dispatch(setErrorSnackBar({ message: 'login_as_user_failed', params: [selectedUserForLoginAs.userId] }))
      setSelectedUserForLoginAs(undefined)
    }
  }, [dispatch, isLoginAsUserIsFailed, selectedUserForLoginAs])

  const tableData = useMemo(() => {
    const { skip, take } = tableConfig
    const { data } = process(
      (accountUsers?.users || []).map((accountUser: AccountUser) => {
        if (!accountUser) {
          return undefined
        }

        return {
          ...(accountUser && {
            ...accountUser,
            linkedAccountsString: accountUser.linkedAccounts
              .map((linkedAccount: LinkedAccount) => linkedAccount.email)
              .join(', ')
          })
        }
      }),
      { skip, take }
    )

    return {
      data: data.filter(accountUser => !!accountUser),
      total: accountUsers?.count || 0
    }
  }, [accountUsers, tableConfig])

  const userActionStates = useMemo(
    () => ({
      [UserActions.edit]: canEditAccountUser,
      [UserActions.resetPassword]: (userType: UserType) =>
        canResetAccountUserPassword && !!loggedInUser && [UserType.manual, UserType.auto].includes(userType),
      [UserActions.logInAs]: canLoginAsAnAccountUser,
      [UserActions.delete]: canDeleteAccountUser
    }),
    [loggedInUser, canEditAccountUser, canResetAccountUserPassword, canLoginAsAnAccountUser, canDeleteAccountUser]
  )

  const isBulkDeleteUsersButtonEnabled = useMemo(() => !!selectedUserIds.length, [selectedUserIds])

  const onChangeTableConfig = useCallback(
    (filterName: keyof UsersTableState, withReset = true) =>
      (e: ChangeEvent<{ value: unknown }>) => {
        dispatch(
          updateUserTableConfig({
            [filterName]: e.target.value as any,
            ...(withReset && { skip: 0 })
          })
        )
      },
    [dispatch]
  )

  const onSearch = useCallback(() => {
    dispatch(updateUserTableConfig({ search, skip: 0 }))
  }, [dispatch, search])

  const onChangeSearch = useCallback((e: ChangeEvent<{ value: unknown }>) => {
    setSearch(e.target.value as string)
  }, [])

  const onResetSearch = useCallback(() => {
    setSearch('')
    dispatch(updateUserTableConfig({ search: '', skip: 0 }))
  }, [dispatch])

  const onPageChange = useCallback(
    (e: GridPageChangeEvent) => {
      isPaginated.current = true
      resetSelectedUserIds()
      dispatch(updateUserTableConfig({ skip: e.page.skip }))
    },
    [dispatch, resetSelectedUserIds]
  )

  const onSortChange = useCallback(
    (e: GridSortChangeEvent) => {
      dispatch(updateUserTableConfig({ sort: e.sort }))
    },
    [dispatch]
  )

  const onToggleRowExpand = useCallback(
    (userId: string) => {
      setExpandedRows(toggleInArray(expandedRows, userId))
    },
    [expandedRows]
  )

  const onSelectedUserForEdit = useCallback((user?: AccountUser) => {
    setSelectedUserForEdit(user)
  }, [])

  const onLoginAsUser = useCallback(
    (user: AccountUser) => {
      setSelectedUserForLoginAs(user)
      dispatch(loginAsUser(user.userId))
    },
    [dispatch]
  )

  const onSelectedUserForDelete = useCallback((user?: AccountUser) => {
    setSelectedUsersForDelete(user && [user.userId])
  }, [])

  const onDeleteSelectedUsers = useCallback(() => {
    setSelectedUsersForDelete(getSelectedIds(tableData.data.map(user => user.userId)))
  }, [getSelectedIds, tableData.data])

  return useMemo(
    () => [
      {
        isInitialLoad,
        tableConfig,
        isGetUsersListInProgress,
        domains,
        search,
        accountUsers,
        tableData,
        selectedUserIds,
        expandedRows,
        userActionStates,
        selectedUserForEdit,
        selectedUsersForDelete,
        selectedUserForLoginAs,
        isUserIdSelected,
        isAllUserIdSelected,
        formAlert,
        isRequestResetPasswordInProgress,
        isBulkDeleteUsersButtonEnabled,
        isDomainsSelectDisabled: !!pdDomainName,
        canDeleteAccountUser,
        isAddUpdateUsersButtonDisabled: !canAddUpdateUsers,
        requestedResetPasswordUserId
      },
      {
        onChangeTableConfig,
        onSearch,
        onChangeSearch,
        onResetSearch,
        onPageChange,
        onSortChange,
        onSelectUserId,
        onSelectAllUserIds,
        onToggleRowExpand,
        onSelectedUserForEdit,
        onSelectedUserForDelete,
        onDeleteSelectedUsers,
        onLoginAsUser,
        onResetPassword: onRequestResetPassword,
        resetAlert: setFormAlert
      }
    ],
    [
      domains,
      accountUsers,
      tableConfig,
      isInitialLoad,
      onChangeTableConfig,
      isGetUsersListInProgress,
      onSearch,
      onChangeSearch,
      onResetSearch,
      search,
      onPageChange,
      onSortChange,
      tableData,
      onSelectUserId,
      selectedUserIds,
      onSelectAllUserIds,
      expandedRows,
      onToggleRowExpand,
      userActionStates,
      selectedUserForEdit,
      selectedUsersForDelete,
      onSelectedUserForEdit,
      onSelectedUserForDelete,
      isUserIdSelected,
      onLoginAsUser,
      isAllUserIdSelected,
      formAlert,
      onRequestResetPassword,
      setFormAlert,
      isRequestResetPasswordInProgress,
      selectedUserForLoginAs,
      isBulkDeleteUsersButtonEnabled,
      onDeleteSelectedUsers,
      pdDomainName,
      canDeleteAccountUser,
      canAddUpdateUsers,
      requestedResetPasswordUserId
    ]
  )
}
