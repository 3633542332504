import { configureStore } from '@reduxjs/toolkit'
import appReducers from 'redux/reducers'
import appConfig from 'config/appConfig'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
  }
}

/* eslint-disable no-underscore-dangle */
const isDevtoolsEnabled = !!(
  appConfig.DEBUG_MODE &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
)
/* eslint-enable no-underscore-dangle */

const store = configureStore({
  reducer: appReducers as any,
  devTools: isDevtoolsEnabled
})

export default store
