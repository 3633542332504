export const FEATURES = {
  EGD_React_Overview_Rollout: 'EGD_React_Overview_Rollout',
  EGD_React_End_User_Rollout: 'EGD_React_End_User_Rollout',
  EGD_React_License_Compliance_Rollout: 'EGD_React_License_Compliance_Rollout',
  EGD_React_Language_Selector_Rollout: 'EGD_React_Language_Selector_Rollout',
  EGD_Temporary_Passcode_Rollout: 'EGD_Temporary_Passcode_Rollout',
  EGD_React_Okta_Rollout: 'EGD_React_Okta_Rollout',
  EGD_Email_Banners_Rollout: 'EGD_Email_Banners_Rollout',
  EGD_React_India_Deployment: 'EGD_React_India_Deployment',
  EGD_React_Reporting_Rollout: 'EGD_React_Reporting_Rollout',
  EGD_React_Scheduled_Reports: 'EGD_React_Scheduled_Reports',
  EGD_React_Admin_Threat_Origins: 'EGD_React_Admin_Threat_Origins'
} as const
