import React, { useMemo } from 'react'
import { Grid, LinearProgress } from '@barracuda-internal/bds-core'
import ConvergedEmailLog from '@barracuda-internal/converged-message-log/dist/HelloWorld'
import { UiState, useEmailLogLogic } from 'components/pages/emailLog/useEmailLogLogic'

const EmailLog: React.FC = () => {
  const { uiState } = useEmailLogLogic()
  return useMemo(
    () => (
      <Grid container data-testid="container">
        {uiState === UiState.INITIALIZING && <LinearProgress />}
        {uiState === UiState.READY && <ConvergedEmailLog />}
      </Grid>
    ),
    [uiState]
  )
}

export default EmailLog
