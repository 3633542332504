import { makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  outerWrapper: {
    margin: theme.spacing(2, 3, 0, 3)
  },
  searchToolWrapper: {
    margin: theme.spacing(0, 2, 2, 0)
  },
  searchTextField: {
    margin: theme.spacing(0, 2, 2, 0),
    backgroundColor: theme.palette.common.white,
    minWidth: 624
  },
  advancedSearch: {
    maxWidth: 630,
    zIndex: 100
  },
  filtersWrapper: {
    '& > *': {
      margin: theme.spacing(0, 2, 0, 0)
    }
  },
  selectMenus: {
    backgroundColor: theme.palette.common.white,
    fontSize: '14px',
    minWidth: '125px',
    padding: theme.spacing(0, 1),
    '& > div:focus': {
      background: 'inherit'
    },
    '&:before': {
      // normal
      borderBottom: 'none !important'
    },
    '&:after': {
      // focused
      borderBottom: 'none !important'
    },
    '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
      // hover
      borderBottom: 'none !important'
    }
  },
  selectMenusWide: {
    minWidth: '160px'
  },
  disableBtn: {
    opacity: 0.6,
    pointerEvents: 'none'
  },
  menuItem: {
    '&.custom': {
      display: 'none'
    }
  }
}))
