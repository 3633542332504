import { makeStyles } from '@barracuda-internal/bds-core'

export const SIDE_MENU_WIDTH = 48
const FOOTER_HEIGHT = 0

export default makeStyles(() => ({
  app: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    overflow: 'auto'
  },
  pathWrapper: {
    height: '100%',
    display: 'flex'
  },
  wrapper: {
    position: 'relative',
    paddingBottom: FOOTER_HEIGHT,
    height: '100%'
  },
  printable: {
    paddingLeft: 0
  },
  sidebar: {
    zIndex: 1300
  }
}))
